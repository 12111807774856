<template>
  <div class="container-fluid bg-light py-3">
    <main class="container-xl">
      <h1 class="text-dark">Haz eficiente tu negocio</h1>
      <!-- <svg width="150%" height="120"
          xmlns="http://www.w3.org/2000/svg" focusable="false">
        <rect x="0" y="0" width="100%" height="100%" fill="#3575CB" rx="10"/>
        <text x="15%" y="60%" class="fs-3" fill="white">Mejora tus operaciones y abre areas de oportunidad en tu empresa</text>
      </svg> -->
      <!-- <p class="fs-3">Contamos con productos, servicios y soluciones que te ayudarán a mejorar tus operaciones</p> -->
      <div class="card mt-2 mb-2 mt-md-3 mb-md-3">
        <div class="row">
          <div class="col-md-2 mt-2">
            <img src="../assets/Logo-Sait.png" alt="modulos" srcset="" class="img-fluid mt-md-4 ms-md-4">
          </div>
          <div class="col-md-8">
            <div class="card-body ">
            <h5 class="card-title">SAIT Software Administrativo</h5>
            <p class="card-text">Diseñado para eficientar el trabajo de las PYMES, con los reportes que necesitas y la contabilidad al día</p>
            <!-- <a href="#" class="btn btn-success fw-bold me-2" @click.prevent="$emit('verFormDescarga',1)" data-bs-toggle="modal" data-bs-target="#Modal">Descargar</a> -->
            <a href="#" class="btn btn-success fw-bold me-2" @click.prevent="$emit('verFormDescarga',1)">Descargar</a>
            <router-link class="btn btn-primary" to="/sait-erp">Ver más</router-link>
            </div>  
          </div>
        </div>
      </div>
      <div class="card mb-2 mb-md-3">
        <div class="row">
        <div class="col-md-2 mt-4">
            <img src="../assets/GoTo_PartnerNetwork_Blk_Yel_HEX.png" alt="gotoconnect" srcset="" class="img-fluid mt-md-4 ms-md-4">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">Telefonía IP y Soluciones de Colaboración</h5>
            <p class="card-text">Telefonía empresarial por Internet. Agiliza la comunicación con clientes y colaboradores para que no pierdas llamadas</p>
            <router-link class="btn btn-primary" to="/servicios/logmein">Ver más</router-link>
          </div>
        </div>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-3 mb-3">
        <div class="col mb-2">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">Respaldos en la nube</h5>
              <p class="card-text">Seguridad para tus archivos con respaldos automáticos en Microsoft Azure&reg;</p>
              <router-link class="btn btn-primary" to="/soluciones/respaldos-nube">Ver más</router-link>
            </div>
            
          </div>  
        </div>
        <div class="col mb-2">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">Sait Nómina</h5>
              <p class="card-text">Automatiza el cálculo de la nómina, así como los recibos para tu personal</p>
              <router-link class="btn btn-primary" to="/sait-nom">Ver más</router-link>
            </div>
          </div>  
        </div>
        <div class="col mb-2">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">Tienda en Línea Sait</h5>
              <p class="card-text">Conéctate al e-commerce para que tengas una sucursal abierta 24/7 sincronizada con tu sistema Sait</p>
              <router-link class="btn btn-primary" to="/sait-ecom">Ver más</router-link>
            </div>
          </div>  
        </div>
      </div>
    </main>
    <section class="container-xl pb-3">
      <hr class="dropdown-divider">
      <h2>Factura 123</h2>
      <div class="row">
        <div class="col-md-4 col-xl-1">
          <img src="../assets/logo-factura123.jpg" alt="">
        </div>
        <div class="col-md-8 col-xl-11">
          <p class="fs-4">Regístrate en el sitio y recibe <strong>5 facturas gratis </strong>.</p>
          <p class="fs-4">10% adicional en timbres en cada compra al ingresar el código <strong>17342</strong></p>
          <p class="fs-5">Emite tus facturas desde cualquier punto o dispositivo y usando tus catálogos</p>
          
        </div>
        <div>
          <a href="https://factura123.mx" target="_blank" class="btn btn-warning">Ir a Factura 123</a>
        </div>
        
      </div>
      
    </section>
    <section class="container-xl pb-3">
      <hr class="dropdown-divider">
      <h2>Equipa tu punto de venta</h2>
      <p class="fs-5">Somos distribuidores de todo el equipo para punto de venta: computadoras, impresoras de tickets y/o etiquetas, cajones de dinero, lectores de códigos de barra,
        servidores tales como POSline, Epson, Zebra, Star Micronics, HP, Dell, ASUS, Acer, Lenovo entre otros.</p>
    </section>
    <img src="../assets/logos-de-marcas.jpg" alt="logosProveedores" srcset="">
  </div>
</template>

<script>
export default {
  name: 'Home',
  // props: {
  //   title: String
  // },
  // setup(props){
  //   console.log(props.title);
  // }
}
</script>