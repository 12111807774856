import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/Home.vue'
const SaitERP = () => import('./components/Sait-ERP')
// const SaitPDV = () => import('./components/Sait-PDV')
const Producto = () => import('./components/Producto')
const Servicio = () => import('./components/Servicio')
const ResNube = () => import('./components/RespaldosNube')
const AvisoPriv = () => import('./components/AvisoPriv')
const LMIGeneral = () => import('./components/LMIGeneral')
const DescargaProducto = () => import('./components/DescargaProducto')
import { estadoRedireccion, reinicio} from './redireccion'

const routes = [
    { path: '/', component: Home},
    { path: '/sait-erp', component: SaitERP, props:{ verSaitERP: true}},
    { path: '/sait-b', component: SaitERP, props: { verSaitERP: false}},
    // { path: '/sait-b', component: SaitPDV},
    { path: '/sait-nom', component: Producto, props:{
        titulo: 'Automatiza el control de los aspectos más importantes de la nómina',
        descripcion: 'SAIT Nómina reduce errores y carga excesiva de trabajo, es adaptable al giro de tu empresa gracias a su fácil manejo y versatilidad. Aumenta tu productividad y optimiza tus cálculos.',
    }},
    { path: '/sait-ecom', component: Producto, props:{
        titulo: 'Potentes herramientas de E-Commerce para pequeños negocios.',
        descripcion: 'Sait Tienda en Línea es una aplicación web que le permite a tus clientes conocer tu catálogo de productos, recibir ofertas y conocer de nuevos productos.',
        verTienda: true
    }},
    { path: '/servicios/logmein', component: LMIGeneral },
    { path: '/servicios/gotoconnect', component: Servicio, props:{
        titulo: 'Comunicacion empresarial flexible',
        descripcion: 'Con llamadas, reuniones y mensajes unificados en una plataforma puede trabajar más y mejor, desde cualquier lugar y en cualquier dispositivo.',
        pathLogo: require('./assets/LMI_GoToConnect_Green_HEX.png'),
        pathImg: require('./assets/img_gotoconnect.jpg')
    }},
    { path: '/servicios/gotomeeting', component: Servicio, props:{
        titulo: 'Videoconferencias rápidas, fiables y seguras',
        descripcion: 'GoToMeeting pone en manos de sus teletrabajadores los recursos que necesitan sin renunciar a la protección de su empresa.',
        pathLogo: require('./assets/LMI_GoToMeeting_Orange_HEX.png'),
        pathImg: require('./assets/img_gotoconnect.jpg')
    }},
    { path: '/servicios/gotowebinar', component: Servicio, props:{
        titulo: 'La plataforma de seminarios web sin complicaciones',
        descripcion: 'Cree rápidamente eventos pregrabados y en directo con GoToWebinar. Automatizamos las partes aburridas para que pueda centrarse en atraer a su público.',
        pathLogo: require('./assets/LMI_GoToWebinar_Blue_HEX.png'),
        pathImg: require('./assets/img_gotoconnect.jpg')
    }},
    { path: '/servicios/logmeinrescue', component: Servicio, props:{
        titulo: 'Asistencia técnica remota con todas las garantías. Y a su medida.',
        descripcion: 'Preste asistencia técnica a PC, Mac y dispositivos iOS, Android y de otras plataformas a cualquier hora y desde cualquier sitio.',
        pathLogo: require('./assets/LMI_Rescue_Blue_HEX.png'),
        pathImg: require('./assets/img_gotoconnect.jpg')
    }},
    { path: '/servicios/logmeinpro', component: Servicio, props:{
        titulo: 'La libertad de estar en dos sitios a la vez',
        descripcion: 'La herramienta de acceso remoto más fiable del mundo',
        pathLogo: require('./assets/LMI_Pro.png'),
        pathImg: require('./assets/img_gotoconnect.jpg'),
    }},
    { path: '/soluciones/respaldos-nube', component: ResNube},
    { path: '/aviso-privacidad', component: AvisoPriv},
    { path: '/aviso-descarga', component: DescargaProducto, props: route => ({
      correo: route.query.correo,
      producto: route.query.producto
    }), beforeEnter: (to,from,next) => {
      if(estadoRedireccion()){
        reinicio();
        next();
      }
      else{
        next('/');
      }
        
    }}
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkExactActiveClass: 'active',
    scrollBehavior (to, from, savedPosition){ 
      if(savedPosition)
        return savedPosition;
      else
        return { left: 0, top: 0 };
    }
})

export default router;