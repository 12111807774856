<template>
    <header class="sticky-top container-fluid bg-secondary py-4 px-0">
        <!-- <h3 class="text-light">Header</h3> -->
        <div class="container-xl flex-wrap flex-md-nowrap">
          <div class="d-flex  justify-content-end pe-3">
            <a href="mailto:ventas@websait.mx" class="text-light text-decoration-none align-self-end px-3 header-ico">
              <i class="bi bi-envelope"></i>
              ventas@websait.mx</a>
            <!-- <div class="d-flex flex-column inline-flex "> -->
              <a href="tel:+528185264534" class="text-light text-decoration-none header-ico px-3">
                <i class="bi bi-telephone"></i> 81 8526 4534</a>
              <a href="tel:+525553408432" class="text-light text-decoration-none header-ico">
                <i class="bi bi-telephone"></i> 55 5340 8432</a>
            <!-- </div> -->
            
          </div>
        </div>
        
        <nav class="navbar navbar-expand-md navbar-dark bg-dark ms-auto">
          <div class="container-xl flex-wrap flex-md-nowrap">
            <router-link class="navbar-brand" to="/" >
              <img src="../assets/Logo-Websait-color-horizontal.png" alt="/" height="80">
            </router-link>    
            
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="navbar-collapse collapse justify-content-center" id="navbarNav">
              <div class="navbar-nav me-5 mb-2 mb-md-0">
                
                <li class="nav-item">
                  <router-link class="nav-link px-3" to="/"  aria-current="page">Soluciones</router-link>
                <!-- <a class="nav-link active px-3" aria-current="page" href="#">Inicio</a> -->
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link nav-dark dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    SAIT
                  </a>
                  <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown">
                    <li><router-link class="dropdown-item" to="/sait-erp">Sait ERP</router-link></li>
                    <li><router-link class="dropdown-item" to="/sait-b">Sait Básico</router-link></li>
                    <li><router-link class="dropdown-item" to="/sait-nom">Sait Nomina</router-link></li>
                    <li><router-link class="dropdown-item" to="/sait-ecom">Sait E-commerce</router-link></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link nav-dark dropdown-toggle" href="#" id="navbarGoto" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    LogMeIn
                  </a>
                  <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown2">
                    <li><router-link class="dropdown-item" to="/servicios/gotoconnect">GotoConnect</router-link></li>
                    <li><router-link class="dropdown-item" to="/servicios/gotomeeting">GotoMeeting</router-link></li>
                    <li><router-link class="dropdown-item" to="/servicios/gotowebinar">GotoWebinar</router-link></li>
                    <li><router-link class="dropdown-item" to="/servicios/logmeinrescue">LogmeIn Rescue</router-link></li>
                    <li><router-link class="dropdown-item" to="/servicios/logmeinpro">LogmeIn Pro</router-link></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link nav-dark dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Microsoft</a>
                  <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown2">
                    <li class="dropdown-item disabled">Microsoft 365</li>
                    <!-- <li><hr class="dropdown-divider"></li> -->
                    <li><router-link class="dropdown-item" to="/soluciones/respaldos-nube">Respaldos en la Nube</router-link></li>
                  </ul>
                </li>
                
              </div>
            </div>

          </div>
        </nav>
    </header>
</template>

<script>
import 'vue-router'

export default {
  name: 'Header',
  /*props: {
    
  },
  setup(props){
    
  }*/
}
</script>

<style scoped>
.header-ico{
  font-size: .9rem;
}
</style>