<template>
    <Header/>
    <div class="modal fade" id="Modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalLabel">Da un paso adelante hacia una mejor administración</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <FormDescarga :seleccion="seleccionForm" ref="formdescarga"/>
          </div>
        </div>
      </div>
    </div>
    <router-view v-slot="{Component}">
      <transition name="fade" mode="out-in">
        <component :is="Component" :key="$route.path" @verFormDescarga="preparaDescarga"/>
      </transition>
    </router-view>
    
    <Footer />
    
    
  
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/modal'
import Header from './components/Header.vue'
// import Home from './components/Home.vue'
// import Footer from './components/Footer.vue'
import { defineAsyncComponent } from '@vue/runtime-core'
const Footer = defineAsyncComponent(() => import ('./components/Footer.vue')) 
const FormDescarga = defineAsyncComponent(() => import ('./components/FormDescarga.vue'))

export default {
  name: 'App',
  components: {
    // Home,
    Footer,
    Header,
    FormDescarga
  },
  data(){
    return {
      seleccionForm: -1
    }
  },
  methods:{
    preparaDescarga(seleccionForm){
      this.seleccionForm = seleccionForm;
      this.$refs.formdescarga.seleccionado = seleccionForm;
      this.$refs.formdescarga.mostrarForm();
    }
  }
}
</script>

<style>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  text-align: center;
  display: flex;
  flex-flow: nowrap column;
  justify-content: space-around;
  margin-block-end: 0;
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .16s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
